<template id="add-this">
    <div class="addthis_inline_share_toolbox"></div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.component('add-this', {
        mounted() {
            if (addthis && addthis.layers && addthis.layers.refresh)
                addthis.layers.refresh();
        },
        template: '#add-this'
    });
</script>
