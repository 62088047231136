import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        info: {},
        has_live_events: false,
        ad_mode: null,
        menu: {
            links: [
                {path: '/archives', title: 'Archives'},
                {path: '/schedule', title: 'Schedule'},
                {path: '/news', title: 'News'},
                {path: '/contact-us', title: 'Contact Us'}
            ]
        },
        ad_banners: null
    },
    mutations: {
        SET_LIVE_EVENTS (state, value) {
            state.has_live_events = value;
        },
        SET_LIVE_INFO (state, value) {
            state.info = value;
        },
        SET_AD_MODE (state, value) {
            state.ad_mode = value;
        },
        SET_AD_BANNERS (state, value) {
            state.ad_banners = value;
        }
    },
    getters: {
        info(state) {
            return state.info;
        },
        liveid (state) {
            return state.info.liveid;
        },
        menu_links (state) {
            return state.menu.links;
        },
        social_links(state) {
            return state.info.social_links;
        },
        has_live_events (state) {
            return state.has_live_events;
        },

        ad_mode (state) {
            return state.ad_mode;
        },
        ad_banners (state) {
            return state.ad_banners;
        }
    }
});