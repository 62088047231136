<template>
  <div class="page-container">
    <main-menu></main-menu>
    <main-background></main-background>
    <main-header></main-header>

    <router-view v-if="is_loaded"></router-view>

    <main-footer></main-footer>
  </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from './components/main/Menu.vue'
    import MainBackground from './components/main/Background.vue'
    import MainHeader from './components/main/Header.vue'
    import MainFooter from './components/main/Footer.vue'

    import CustomRecentItems from './components/custom/recent.vue'
    import CustomEventPhotos from './components/custom/event-photos.vue'
    import CustomDateTime from './components/custom/datetime'
    import CustomAjaxLoader from './components/custom/ajax-loader'
    import CustomMessage from './components/custom/message'
    import CustomAdsBlock from './components/custom/ads-block.vue'
    import CustomSignUpBlock from './components/custom/signup-block.vue'
    import CustomAddThis from './components/custom/add-this.vue'

    export default {
        components: {
            'main-header': MainHeader,
            'main-background': MainBackground,
            'main-menu': MainMenu,
            'main-footer': MainFooter,

            'ads-block': CustomAdsBlock,
            'event-photos': CustomEventPhotos,
            'add-this': CustomAddThis,
            'recent-items': CustomRecentItems,
            'datetime': CustomDateTime,
            'ajax-loader': CustomAjaxLoader,
            'message': CustomMessage,
            'signup-block': CustomAdsBlock
        },
        computed: {
            enable_ad_bar() { return this.$store.getters.ad_mode != null; },
            is_loaded() { return this.$store.getters.info.name != null; }
        },
        created() {
            this.$store.commit('SET_LIVE_INFO', {
                liveid: window.meridix.liveid,
                header_url: window.meridix.header_url
            });

            // Load group information
            this.$http.get('content/group.php', {
                params: {
                  liveid: window.meridix.liveid,
                }
            }).then(response => {
                console.log('loaded', response.body);
                this.$store.commit('SET_LIVE_INFO', response.body);
                this.$store.commit('SET_AD_MODE', response.body.ad_mode);
                this.$store.commit('SET_AD_BANNERS', response.body.ad_banners);
            });
        }
    }
</script>
