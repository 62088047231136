<template id="event-photos">
    <div class="panel album-list">
        <div class="panel-header">
            <h3>Photos</h3>
        </div>

        <div class="album-list-body">
            <div class="album-item" v-for="item in _items" :key="item.id">
                <div class="album-item-images">
                    <div class="album-item-image">
                        <a :href="`/event/${item.event_id}?open=photo_${item.photos[0].id}`">
                            <img :src="item.photos[0].src">
                        </a>
                    </div>
                    <div class="album-item-image" v-if="item.photos.length >= 2">
                        <a :href="`/event/${item.event_id}?open=photo_${item.photos[1].id}`">
                            <img :src="item.photos[1].src">
                        </a>
                    </div>
                    <div class="album-item-image" v-if="item.photos.length >= 3">
                        <a :href="`/event/${item.event_id}?open=photo_${item.photos[2].id}`">
                            <img :src="item.photos[2].src">
                            <div class="album-item-image-overlay">
                                {{ item.count_of_photos }}+
                            </div>
                        </a>
                    </div>
                </div>
                <label>
                    <a :href="`/event/${item.event_id}`">
                        <p class="album-item-timestamp">
                            {{ date(item) }}
                        </p>
                        {{ item.title }}
                    </a>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment';

export default Vue.component('event-photos', {
    props: ['name', 'title', 'empty', 'items', 'length', 'root'],
    methods: {
        date(item) {
            return moment(item.timestamp * 1000).format("MMM D, YYYY")
        },
        hasMoreItems() {
            return this.max && this.items.length > this.max;
        }
    },
    computed: {
        _items() { return this.max ? this.items.slice(0, this.max) : this.items },
        _title() { return this.title ? this.title : 'Recent ' + this.name.toLowerCase() },
        _empty() { return this.empty ? this.empty : 'No ' + this.name.toLowerCase() },
        _more()  { return this.name  ? 'More ' + this.name : 'More' },

        max() { return this.length ? this.length : 5; },
    },
    template: '#event-photos'
})
</script>