<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" @click="close">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'

export default Vue.component('modal', {
    name: 'modal',
    props: ['item'],
    methods: {
        close() {
            if (! $(".modal-container").is(':hover')) { this.$parent.close() }
        }
    },
    template: '#modal-template'
});
</script>