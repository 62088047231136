import Vue from 'vue'
import moment from "moment";

export default Vue.component('datetime', {
    props: ['timestamp', 'type', 'format'],
    computed: {
        date_format() { return this.format ? this.format : 'MM/DD/Y'; },
        ago: function() {
          const t = moment(this.timestamp * 1000);
          const now = moment();
          if (now.diff(t, 'hours') < 12) {
            return t.fromNow();
          } else if (t.year() === now.year()) {
            return t.format('MMM D');
          } else {
            return t.format('MMM D, Y');
          }
        },
        full: function() {
          return moment(this.timestamp * 1000).format(this.date_format)
        },
        hover: function() {
          const m = moment(this.timestamp * 1000);
          const withTime = m.format('h:mm:ss A') != '12:00:00 AM';
          return m.format('MM/DD/Y' + (withTime ? ' h:mm:ss A' : ''));
        },

        render: function() {
            return (this.type == 'ago') ? this.ago : this.full;
        }
    },
    template: '<span class="datetime" :title="hover">{{ render }}</span>'
});
