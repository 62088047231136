<template>
  <header id="header-nav">
    <nav class="nav">
      <router-link :to="{path: '/'}" activeClass="nav-link-active" exact class="nav-link">Home</router-link>
      <router-link :to="{path: '/archives'}" activeClass="nav-link-active" class="nav-link">Archives</router-link>
      <router-link :to="{path: '/schedule'}" activeClass="nav-link-active" class="nav-link">Schedule</router-link>
      <router-link :to="{path: '/news'}" activeClass="nav-link-active" class="nav-link">News</router-link>
      <router-link :to="{path: '/contact-us'}" activeClass="nav-link-active" class="nav-link hidden-xs">Contact us</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    links() {
      return this.$store.getters.menu_links;
    }
  },
  created() {
    $(".dropdown-toggle").dropdown();
  }
};
</script>