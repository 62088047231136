import Vue from 'vue'

export default Vue.directive('img-fallback', {
    bind: function(el, binding) {
        const { value } = binding;
        const img = new Image();

        let original = el.src;
        let candidates = [original];

        if (typeof value === 'string') {
            candidates.push(value);
        } else if (typeof value === 'object') {
            candidates = [...candidates, ...value].filter(x => !!x);
        }

        function moveNext(index) {
            if (index >= candidates.length) {
                return;
            }

            const candidate = candidates[index];

            img.addEventListener("load", () => {
                el.src = candidate;
            }, { once: true } );
            
            img.addEventListener("error", () => {
                moveNext(index + 1);
            }, { once: true } );

            img.src = candidate;
        }

        moveNext(0);

        // todo: cleanup and ensure our temporary image is abandoned
    }
});