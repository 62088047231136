<template id="signup-block">
    <div class="panel panel-signup text-center">
        <h3>Be notified</h3>
        <p>Never miss an upcoming event or news from {{ channel.name }}.</p>
        <a class="btn btn-primary" disabled>Coming Soon</a>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.component('signup-block', {
        computed: {
            url() {
                return '/my_alerts.php?liveid=' + this.$store.getters.liveid;
            },
            channel() {
                return this.$store.getters.info;
            }
        },
        template: '#signup-block'
    });
</script>