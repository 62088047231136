<template id="google-captcha">
    <div class="google-captcha"></div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.component('google-captcha', {
        mounted: function () {
            this.rerender();
        },
        methods: {

            rerender() {

                if (window.grecaptcha !== undefined){

                    window.grecaptcha.render(this.$el, {
                        sitekey: this.sitekey,
                        callback: function (response){
                            this.captcha.value = response;
                        }.bind(this),
                    });

                    return;
                }

                setTimeout(this.rerender.bind(this), 500);
            }
        },
        props: ['captcha', 'sitekey'],
        template: '#google-captcha'
    });
</script>