<template id="ads-block">
    <div v-if="mode != null" class="ad" v-bind:class="{'mt': position == 'bottom'}">

        <ins v-if="mode == 'google' && visible && position == 'mtop'" class="adsbygoogle"
             style="display:block; width: 100%; height: 100px;"
             data-ad-client="ca-pub-0694296842670811"
             :data-ad-slot="slot"
             data-full-width-responsive="true"></ins>

        <ins v-else-if="mode == 'google' && visible" class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-0694296842670811"
             :data-ad-slot="slot"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>

        <div v-if="mode == 'custom' && banner && (banner.banner_position == position || banner.banner_position == 'any')" class="text-center">

            <object v-if="(banner.banner_extension == 'swf' && position != 'right')" :height="banner.swf_height" :width="banner.swf_width">
                <param name="movie" :value="'/mbp/login/banners/uploadFiles/' + banner.banner_filename">
                <embed :src="'/mbp/login/banners/uploadFiles/' + banner.banner_filename" :height="banner.swf_height" :width="banner.swf_width"></embed>
            </object>

            <iframe v-else-if="(banner.banner_extension == 'code' && position != 'right')"
                    :src="'/mbpbanners_network.php?BannerID=' + banner.BannerID"
                    width="468" height="90" scrolling="no" frameborder="0" style="margin-bottom: -20px;" marginwidth="0" marginheight="0" allowtransparency="true"></iframe>

            <iframe v-else-if="(banner.banner_extension == 'code' && position == 'right')"
                    :src="'/mbpbanners_network.php?BannerID=' + banner.BannerID"
                    width="90" height="468" scrolling="no" frameborder="0" style="margin-bottom: -20px;" marginwidth="0" marginheight="0" allowtransparency="true"></iframe>

            <a :href="banner.banner_url" target="_blank" v-else>
                <img :src="'/mbp/login/banners/uploadFiles/' + banner.banner_filename" width="100%"/>
            </a>

        </div>
    </div>

</template>

<script>
    import Vue from 'vue';

    export default Vue.component('ads-block', {
        data() {
            return {
                banners: [],
                banner: null,
                interval: null,

                index: 0
            };
        },
        props: ['position'],
        computed: {

            slot() {

                var slots = {
                    'top': '5292953203',
                    'right': '6974836007',
                    'bottom': '8680684006',
                    'mtop': '1102257470',
                    'mbottom': '6335111663'
                };

                return slots[this.position];
            },

            mode() { return this.$store.getters.ad_mode; },

            visible() {
                const position = this.position;       
                if (position) {
                    const isMobilePosition = this.position.startsWith('m');
                    const isMobileScreen = window.innerWidth <= 768;

                    return isMobilePosition === isMobileScreen;
                }
            }
        },
        methods: {
            randomize() {
                if (this.index >= this.banners.length)
                    this.index = 0;

                this.banner = this.banners[this.index++];
            },

            shuffle(a) {
                var j, x, i;
                for (i = a.length - 1; i > 0; i--) {
                    j = Math.floor(Math.random() * (i + 1));
                    x = a[i];
                    a[i] = a[j];
                    a[j] = x;
                }
            }
        },
        mounted() {

            this.banners = this.$store.getters.ad_banners;
            if(this.banners) {
                this.banners = this.banners.filter(function(banner){
                    if(banner)
                        return banner.banner_position == this.position || banner.banner_position == 'any';
                }.bind(this));
            }

            if (this.mode == 'google' && this.visible) {
                (window.adsbygoogle = window.adsbygoogle || []).push({})
            } else if (this.mode == 'custom') {

                this.shuffle(this.banners);
                this.randomize();
                this.interval = setInterval(this.randomize, 60000);
            }
        },
        destroyed() { clearInterval(this.interval) },
        template: '#ads-block'
    });
</script>
