<template>
        <div class="container">

        <div class="row">
            <div class="col-lg-8">
                <ads-block position="top" v-if="enable_ad_bar() && content_ready()"></ads-block>
                <div v-if="display_content()">
                    <div v-for="item in items" class="panel panel-default post">
                        <div class="panel-heading">
                            <router-link :to="{to: '/:id', params: {'id': item.id}}"><h4 class="channel-text">{{ item.title }}</h4></router-link>
                            <div class="label label-default channel-header-color" v-if="item.liveid">{{ item.liveid }}</div>
                            <datetime :timestamp="item.timestamp" type="ago"></datetime>
                            <span class="datetime">&bull; {{ item.count_of_photos }} photos</span>
                        </div>
                        <div class="panel-body gallery row">
                            <div class="col-lg-2 col-sm-4 col-xs-6" v-for="(photo, index) in item.photos">
                                <a :href="photo" v-bind:data-lightbox="item.id" v-if="single || (!single && index != 11) || item.count_of_photos == 12">
                                    <img :src="photo">
                                </a>

                                <a :href="'#/photos/' + item.id" v-if="!single && index == 11 && item.count_of_photos != 12" class="more channel-color">
                                    <span class="">+ {{ item.count_of_photos -  item.photos.length + 1 }} more</span>
                                    <img :src="photo">
                                </a>
                            </div>
                        </div>
                    </div>

                    <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

                    <div class="row" v-if="has_more() && !is_pending()">
                        <div class="col-lg-12 text-center">
                            <button class="btn btn-default channel-btn" @click="fetch">More albums</button>
                        </div>
                    </div>
                </div>
                <ads-block position="bottom" v-if="enable_ad_bar() && content_ready()"></ads-block>
            </div>

            <div class="col-lg-4">
                <div v-if="display_content()">
                    <div class="panel panel-default featured">	
                        <div class="panel-body text-center">	
                            <add-this></add-this>	
                        </div>	
                    </div>

                    <recent-items root="/photos" :items="recent" name="Photo albums"></recent-items>

                    <signup-block></signup-block>

                    <div class="hidden-xs">
                        <ads-block v-if="enable_ad_bar() && content_ready()" position="right"></ads-block>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <ajax-loader :hide="is_ready()"></ajax-loader>
                <message text="No photos found" :show="no_content() && is_ready()"></message>
            </div>
        </div>
    </div>
</template>

<script>
    import Paginatable from '../components/custom/paginatable'
    import AdBar from '../components/custom/ad-bar'

    export default {
        data() {
            return {
                resource: '/photos.php'
            }
        },
        mixins: [Paginatable, AdBar]
    }
</script>
