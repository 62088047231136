<template>
  <div class="container">
    <div class="row" v-show="!no_content()">
      <div class="col-8 col-xs-12">
        <div v-if="enable_ad_bar() && is_ready()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div v-if="is_ready()">
          <div class="panel panel-schedule" v-for="item in items" :key="item.id">
            <div class="panel-schedule-date">
              <span class="panel-schedule-date-month">{{ datePartMonth(item) }}</span>
              <span class="panel-schedule-date-day">{{ datePartDay(item) }}</span>
              <span class="panel-schedule-date-year">{{ datePartYear(item) }}</span>
            </div>

            <div class="panel-schedule-info">
              <h3 class="panel-schedule-info-title">
                <div class="panel-schedule-info-title-badge" v-if="item.status === 'L'">LIVE</div>
                <a :href="item.event_url" target="_blank">
                  {{ item.event_name }}
                </a>
              </h3>
              <ul class="panel-schedule-info-description">
                <li>
                  <div>
                    <span v-if="item.liveid" class="badge badge-channel">{{ item.liveid }}</span>
                  </div>
                  {{ item.time }}
                  <span v-if="item.timezone_abbr">{{ item.timezone_abbr }}</span>
                </li>
                <li>
                  <i :class="`icon-${item.event_type.toLowerCase()}`"></i>
                  {{ item.event_type }}
                </li>
              </ul>
            </div>

            <div class="panel-schedule-actions hidden-xs">
              <a class="btn-secondary btn-xs" :href="item.event_url" target="_blank">
                <span v-if="item.br_type == 2">Listen</span>
                <span v-if="item.br_type != 2">Watch</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
          </div>

          <div class="text-center" v-if="has_more() && !is_pending()">
            <a class="btn btn-secondary width-full btn-xs text-center channel-page-more" @click="fetch">Load more</a>
          </div>
        </div>

        <div v-if="enable_ad_bar() && is_ready()" class="hidden-xs">
          <ads-block position="bottom"></ads-block>
        </div>
      </div>

      <div class="col-4 col-xs-12">
        <signup-block></signup-block>

        <div class="panel">
          <div class="panel-body text-center">
            <add-this></add-this>
          </div>
        </div>

        <template v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="right"></ads-block>
          </div>
        </template>
      </div>
    </div>

    <message text="No scheduled events found" :show="no_content() && content_ready()"></message>
    <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

    <div v-if="enable_ad_bar() && !no_content()">
      <div class="visible-xs">
        <ads-block position="mbottom"></ads-block>
      </div>
    </div>
  </div>
</template>
<script>
import CustomModal from "../components/custom/modal.vue";
import Paginatable from "../components/custom/paginatable";
import AdBar from "../components/custom/ad-bar";
import moment from "moment";

export default {
  data() {
    return {
      details: null,
      resource: "/schedule.php"
    };
  },
  methods: {
    open(item) {
      this.details = item;
    },
    close() {
      this.details = null;
      this.$router.push({ path: "/schedule" });
    },
    loaded() {
      if (this.$route.params.id) {
        for (var index in this.items) {
          if (this.items[index].id == this.$route.params.id) {
            this.open(this.items[index]);
            return;
          }
        }
      }
    },
    datePartMonth(item) {
      var date = moment(item.local_date);
      return date.format("MMM");
    },
    datePartDay(item) {
      var date = moment(item.local_date);
      return date.format("D");
    },
    datePartYear(item) {
      var date = moment(item.local_date);
      return date.format("YYYY");
    }
  },
  components: { modal: CustomModal },
  mixins: [Paginatable, AdBar]
};
</script>