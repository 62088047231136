<template>
  <div class="site-background">
    <div class="background-placeholder" :style="{ backgroundImage: `url(${header_url})` }"></div>
  </div>
</template>


<script>
export default {
  computed: {
    header_url() {
      const channelInfo = this.$store.getters.info;
      if (channelInfo && channelInfo.header_url) {
          return channelInfo.header_url;
      } else {
        return "/channel/dist/images/events/General.jpg";
      }
    }
  }
};
</script>