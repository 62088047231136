<template>
  <div class="container" style="flex-grow: 0">
    <div class="channel-info-block channel-info-block-left">
      <div class="channel-info-block-logo" v-if="logo_url">
        <router-link :to="{path: '/'}">
          <img :src="logo_url" width="48" height="48" />
        </router-link>
      </div>
      <div>
        <h1 class="channel-info-block-title">{{ name }}</h1>
        <span class="channel-info-block-subtitle">{{ location }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
      name() { return this.$store.getters.info.name },
      location() { return this.$store.getters.info.location },
      logo_url() { return this.$store.getters.info.logo_url }      
  }
};
</script>