<template>
    <footer>
        <div class="footer-signature">
            <h3 class="footer-signature-logo">
                <a href="/">
                    <img src="/images/logo_footer_mbp.png">
                </a>
            </h3>
            <div class="footer-signature-seperator"></div>
            <p class="footer-signature-text">
                <router-link :to="{path: '/'}" activeClass="nav-link-active" class="nav-link">{{ name }}</router-link>
                <span class="footer-signature-text-contact">
                    <router-link :to="{path: '/contact-us'}" activeClass="nav-link-active" class="nav-link">Contact Us</router-link>
                </span>
            </p>
        </div>
    </footer>
</template>
<script>
    export default {
        computed: {
            social_links() { return this.$store.getters.social_links; },
            links() { return this.$store.getters.menu_links; },
            name() { return this.$store.getters.info.name; }
        }
    }
</script>