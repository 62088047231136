<template>
  <div class="container">
    <div class="row">
      <div class="col-8 col-xs-12">
        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div class="panel panel-form panel-padding-large visible-xs panel-search">
          <div class="panel-body">
            <form @submit.prevent="applyFilters">
              <div class="form-control">
                <input type="search" v-model="query" placeholder="Search event" />
              </div>

              <div
                class="collapsible"
                v-bind:class="{ 'collapsible-collapsed': filter_details_collapsed  }"
              >
                <div class="form-control">
                  <label>Event type</label>
                  <select v-model="filters.EventType">
                    <option value selected>All</option>
                    <option value>All Event Types</option>
                    <option>Baseball</option>
                    <option>Basketball</option>
                    <option>Ceremony</option>
                    <option>Education</option>
                    <option>Football</option>
                    <option>General</option>
                    <option>Hockey</option>
                    <option>Music Events (Concerts)</option>
                    <option>Radio Show</option>
                    <option>Soccer</option>
                    <option>Softball</option>
                    <option>Swimming and Diving</option>
                    <option>Tennis</option>
                    <option>Track / Running</option>
                    <option>Volleyball</option>
                    <option>Wrestling</option>
                  </select>
                </div>

                <div class="form-control">
                  <label>Broadcast type</label>
                  <select v-model="filters.BrType">
                    <option value selected>All Types</option>
                    <option>Audio-Only Events</option>
                    <option>Video Events</option>
                  </select>
                </div>

                <div class="form-control">
                  <label>Status</label>
                  <select v-model="filters.Status">
                    <option value selected>All Statuses</option>
                    <option>Live Right Now</option>
                    <option>Completed / Archived</option>
                  </select>
                </div>

                <div class="form-control">
                  <div class="row">
                    <div class="col-4">
                      <label>Year</label>
                      <select v-model="filters.EventYear">
                        <option value selected>All</option>
                        <option v-for="yearOffset in lastYear - firstYear" :key="yearOffset">{{lastYear - yearOffset}}</option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label>Month</label>
                      <select v-model="filters.EventMonth">
                        <option value selected>All</option>
                        <option v-for="month in months" :key="month">{{ month }}</option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label>Day</label>
                      <select v-model="filters.EventDay">
                        <option value selected>All</option>
                        <option v-for="d in 31" :key="d">{{ d + 1 }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary btn-xs width-full">Search</button>
            </form>
          </div>
          <div class="panel-footer text-right">
            <a
              class="link uppercase"
              @click="toggleFilterCollapsed"
              v-if="filter_details_collapsed"
            >
              Expand filters
              <i class="icon icon-arrow-down"></i>
            </a>
            <a class="link uppercase" @click="toggleFilterCollapsed" v-else>
              Collapse filters
              <i class="icon icon-arrow-up"></i>
            </a>
          </div>
        </div>

        <div v-for="item in items" :key="item.id" class="panel event-summary">
          <a class="event-summary-image" :href="'/event/' + item.RecordId">
            <img
              v-if="item.BrType == 'Audio-Only Events'"
              :src="'/images/audio_only/' + (item.EventType != 'Radio Show' ? item.EventType : 'radio').toLocaleLowerCase() + '_ao_sm.jpg'"
            />
            <img
              v-if="item.BrType != 'Audio-Only Events'"
              :src="'//media.meridix.com/thumbnails/' + item.RecordId + '.jpg'"
              @error="$event.target.src='/images/preview_unavailable.jpg'"
            />
            <div class="event-summary-image-meta">
              <p class="event-summary-image-timestamp">
                <span class="badge badge-channel">{{ item.LiveId }}</span> 
                <datetime :timestamp="parseTimestamp(item)" type="ago"></datetime>
                <span style="padding: 0 8px">|</span>
                <a @click.prevent="purchase($event)" :href="'/archive_purchase.php?EventID=' + item.RecordId + '&adding=add'">Buy Download</a>
              </p>
              <h2 class="event-summary-image-title">{{ item.EventName }}</h2>
              <div class="event-summary-image-play">
                <i class="icon-play"></i>
              </div>
              <div class="event-summary-image-badge" v-if="item.Status == 'Live Right Now'">Live</div>
            </div>
          </a>

          <div class="event-summary-info">
            <div class="event-summary-info-meta">
              <p class="event-summary-info-timestamp">
                <div>
                  <span class="badge badge-channel">{{  item.LiveId }}</span>
                </div>
                <i :class="`icon-${item.EventType.toLowerCase()}`"></i>
                {{item.EventType}}
                <span style="padding: 0 8px">|</span>
                <span v-if="item.Status == 'Live Right Now'">LIVE NOW</span>
                <datetime v-else :timestamp="parseTimestamp(item)" type="ago"></datetime>
                <span style="padding: 0 8px">|</span>
                <a @click.prevent="purchase($event)" :href="'/archive_purchase.php?EventID=' + item.RecordId + '&adding=add'">Buy Download</a>
              </p>
              <a
                class="event-summary-info-title"
                :href="'/event/' + item.RecordId"
              >{{ item.EventName }}</a>
            </div>
            <div class="event-summary-info-teams" v-if="item.InterfaceType === 'Advanced'">
              <div class="event-summary-info-team">
                <div class="event-summary-info-team-score">
                  <img
                    :src="item.AwayLiveId ? `signup/images/logos/${item.AwayLiveId.toLowerCase()}.gif` : null"
                    v-img-fallback="[`/mbp/login/uploads/${item.RecordId}_a.gif`, '/images/company/logos/MRDX_Sails_Logo.jpg']"
                  />
                  <label>{{ item.AwayName }}</label>
                  <span>{{ item.AwayScore }}</span>
                </div>
                <span class="event-summary-info-team-rank">{{ item.Time || '-' }}</span>
              </div>

              <div class="event-summary-info-team">
                <div class="event-summary-info-team-score">
                  <img
                    :src="item.HomeLiveId ? `signup/images/logos/${item.HomeLiveId.toLowerCase()}.gif` : null"
                    v-img-fallback="[`/mbp/login/uploads/${item.RecordId}_h.gif`, '/images/company/logos/MRDX_Sails_Logo.jpg']"
                  />
                  <label>{{ item.HomeName }}</label>
                  <span>{{ item.HomeScore }}</span>
                </div>
                <span
                  class="event-summary-info-team-rank"
                >{{ formatPeriod(item.Period) || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
        <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

        <div class="panel no-results" v-if="no_content() && is_ready()">
          <div>
            <i class="icon-magnifier icon-xl"></i>
            <h3>No results found</h3>
            <p>Please try with a different keyword or different filters.</p>
          </div>
        </div>

        <div v-if="!no_content() && has_more() && !is_pending()">
          <a class="btn btn-secondary width-full btn-xs text-center channel-page-more" @click="fetch">Load more</a>
        </div>

         <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="bottom"></ads-block>
          </div>
        </div>
      </div>

      <div class="col-4 col-xs-12">
        <div class="panel panel-form panel-padding-large hidden-xs">
          <form @submit.prevent="applyFilters">
            <div class="form-control">
              <input type="search" v-model="query" placeholder="Search event" />
            </div>

            <div class="form-control">
              <label>Event type</label>
              <select v-model="filters.EventType">
                <option value selected>All</option>
                <option value>All Event Types</option>
                <option>Baseball</option>
                <option>Basketball</option>
                <option>Ceremony</option>
                <option>Education</option>
                <option>Football</option>
                <option>General</option>
                <option>Hockey</option>
                <option>Music Events (Concerts)</option>
                <option>Radio Show</option>
                <option>Soccer</option>
                <option>Softball</option>
                <option>Swimming and Diving</option>
                <option>Tennis</option>
                <option>Track / Running</option>
                <option>Volleyball</option>
                <option>Wrestling</option>
              </select>
            </div>

            <div class="form-control">
              <label>Broadcast type</label>
              <select v-model="filters.BrType">
                <option value selected>All Types</option>
                <option>Audio-Only Events</option>
                <option>Video Events</option>
              </select>
            </div>

            <div class="form-control">
              <label>Status</label>
              <select>
                <option value selected>All Statuses</option>
                <option>Live Right Now</option>
                <option>Completed / Archived</option>
              </select>
            </div>

            <div class="form-control">
              <div class="row">
                <div class="col-4">
                  <label>Year</label>
                  <select v-model="filters.EventYear">
                    <option value selected>All</option>
                    <option v-for="yearOffset in lastYear - firstYear" :key="yearOffset">{{lastYear - yearOffset}}</option>
                  </select>
                </div>
                <div class="col-4">
                  <label>Month</label>
                  <select v-model="filters.EventMonth">
                    <option value selected>All</option>
                    <option v-for="month in months" :key="month">{{ month }}</option>
                  </select>
                </div>
                <div class="col-4">
                  <label>Day</label>
                  <select v-model="filters.EventDay">
                    <option value selected>All</option>
                    <option v-for="d in 31" :key="d">{{ d + 1 }}</option>
                  </select>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary btn-xs width-full">Search</button>
          </form>
        </div>

        <signup-block></signup-block>

        <div class="panel">
          <div class="panel-body text-center">
            <add-this></add-this>
          </div>
        </div>

        <div v-if="enable_ad_bar() && content_ready()" class="hidden-xs">
          <ads-block position="right"></ads-block>
        </div>
      </div>
    </div>
    <div v-if="enable_ad_bar() && content_ready()" class="visible-xs">
      <ads-block position="mbottom"></ads-block>
    </div>
  </div>
</template>

<script>
    import Paginatable from '../components/custom/paginatable'
    import AdBar from '../components/custom/ad-bar'

    export default {
        data() {
            return {
                firstYear: 2010,
                lastYear: new Date().getUTCFullYear(),
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                gamesWithScore: ['baseball', 'basketball', 'football', 'hockey', 'soccer', 'softball', 'volleyball', 'wrestling'],
                algolia: {
                    page: 0,
                    client: null,
                    index: null
                },
                enabled_filters: false,
                filter_details_collapsed: true,
                query: '',
                filters: {
                    EventType: '',
                    Status: '',
                    BrType: '',

                    EventDay: '',
                    EventYear: '',
                    EventMonth: ''
                }
            };
        },
        computed: {
            filtersQuery() {

                var members = this.$store.getters.info.members;

                var query = '((LiveId:' + members.join(' OR LiveId:') + ') OR (OfficialLiveId:' + members.join(' OR OfficialLiveId:') + ') OR (HomeLiveId:' + members.join(' OR HomeLiveId:') + ') OR (AwayLiveId:' + members.join(' OR AwayLiveId:') + '))';
                var count = 1;

                console.log(query);
                for(var i in this.filters) {
                    if (this.filters[i] != null && this.filters[i] != '') {
                        query = query + (count > 0 ?" AND " : "") + i + ':"' + this.filters[i] + '"';
                        count++;
                    }
                }

                return query;
            }
        },
        methods: {
            range(start, count) {
                return Array.apply(0, Array(count))
                    .map(function (element, index) {
                        return index + start;
                    });
            },
            parseTimestamp(item) {
                return Date.parse(item.EventDay + ' ' + item.EventMonth + ' ' + item.EventYear) / 1000;
            },
            showGameInfo(item) {
                return this.gamesWithScore.indexOf(item.EventType.toLocaleLowerCase()) != -1;
            },
            init() {
                this.is_more = false;
                this.pending = false;
                this.items = null;

                var algolia_connect = {
                    app_id:"Z5EBEQNUQ1",
                    api_key:"7f7e606c36257e1e4ee736e786f3490b",
                    index_events:"dev_events",
                    index_channels:"dev_channels",
                    index_schedules:"dev_schedules"
                };

                this.algolia.page = 0;
                this.algolia.client = algoliasearch(algolia_connect.app_id, algolia_connect.api_key);
                this.algolia.index = this.algolia.client.initIndex(algolia_connect.index_events);

                this.fetch();
                this.recent = [];
            },
            applyFilters() {
                this.items = [];
                this.is_more = false;
                this.algolia.page = 0;

                this.fetch();
            },
            fetch() {

                var t = this;

                t.pending = true;
                t.algolia.index.search({'filters': t.filtersQuery, query: t.query, page: t.algolia.page}, function(error, response) {

                    t.items = (t.items && response.hits)
                        ? t.items.concat(response.hits)
                        : response.hits;

                    t.is_more = (response.page != response.nbPages - 1);
                    t.pending = false;
                    t.algolia.page++;
                });
            },
            purchase(event) {
              $.fancybox.open({
                type: "iframe",
                src: event.target.href,
                autosize: false,
                iframe: {
                  css: {
                    width: "1128px",
                    maxWidth: "90%",
                    maxHeight: "90%"
                  }
                }
              });
            },
            toggleFilterCollapsed() {
                this.filter_details_collapsed = !this.filter_details_collapsed;
            },
            formatPeriod(period) {
                if (period) {
                    var parts = period.split(' ');
                    if (parts.length) {
                    return parts[0];
                    }
                }

                return period;
            }
        },
        watch: {
            '$route': function() {},
            filters: {
                handler: 'applyFilters',
                deep: true
            }
        },
        mixins: [Paginatable, AdBar]
    }
</script>
