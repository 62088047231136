<template>
  <div class="container">
    <div class="row row-exclude-xs">
      <div class="col-8">
        <div v-if="enable_ad_bar()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div class="panel panel-padding-large">
          <h2>Write to {{ channel.name }}</h2>
          <p>Send your message directly to the broadcaster of this event.</p>

          <form @submit.prevent="submit">
            <template v-if="!sent">
              <div class="row">
                <div class="col-6">
                  <div class="form-control">
                    <label>
                      Your name
                      <input
                        type="text"
                        v-model="data.username"
                        placeholder="Name"
                        maxlength="256"
                        required
                      />
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-control">
                    <label>
                      Your email
                      <input
                        type="email"
                        v-model="data.email"
                        placeholder="Email"
                        maxlength="256"
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-control">
                <label>
                  Subject
                  <input
                    type="text"
                    v-model="data.subject"
                    placeholder="Subject"
                    maxlength="256"
                    required
                  />
                </label>
              </div>

              <div class="form-control">
                <label>
                  Your message
                  <textarea
                   v-model="data.message">
                  </textarea>
                </label>
              </div>
            </template>

            <message :show="sent" text="Message was sent"></message>
            <div
              class="alert alert-danger text-center"
              style="color: #000;"
              v-if="error"
              v-html="error"
            >{{ error }}</div>

            <ajax-loader :hide="!pending" inline="true"></ajax-loader>

            <div v-if="!pending && !sent" class="row">
              <div class="col-6">
                <google-captcha
                  :captcha="captcha.value"
                  sitekey="6LeCR_ASAAAAABIw18IMGqy62uaEThROrwpb0RGI"
                ></google-captcha>
              </div>
              <div class="col-6 text-right">
                <button type="submit" class="btn btn-primary btn-xs">Send message</button>
              </div>
            </div>
          </form>
        </div>

        <div v-if="enable_ad_bar()">
          <div class="hidden-xs">
            <ads-block position="bottom"></ads-block>
          </div>
        </div>
      </div>

      <div class="col-4 col-xs-12">
        <!-- <div class="panel panel-contact-info panel-padding-large">
          <div class="panel-header">
            <h3>Contact info</h3>
          </div>
          <div class="panel-contact-info-line" v-if="channel.contact_address">
            <div class="panel-contact-info-line-image">
              <i class="icon-home"></i>
            </div>
            <div class="panel-contact-info-line-data">
              <label>Address</label>
              <span class="panel-contact-info-line-value">{{ channel.contact_address }}</span>
            </div>
          </div>

          <div class="panel-contact-info-line" v-if="channel.contact_email">
            <div class="panel-contact-info-line-image">
              <i class="icon-envelope"></i>
            </div>
            <div class="panel-contact-info-line-data">
              <label>Email</label>
              <a class="link">{{ channel.contact_email }}</a>
            </div>
          </div>

          <div class="panel-contact-info-line" v-if="channel.contact_phone">
            <div class="panel-contact-info-line-image">
              <i class="icon-phone"></i>
            </div>
            <div class="panel-contact-info-line-data">
              <label>Phone number</label>
              <a
                :href="'tel:' + channel.contact_phone"
                class="panel-contact-info-line-value"
              >{{ channel.contact_phone }}</a>
            </div>
          </div>

          <div class="panel-contact-info-line" v-if="channel.contact_website">
            <div class="panel-contact-info-line-image">
              <i class="icon-globe"></i>
            </div>
            <div class="panel-contact-info-line-data">
              <label>Website</label>
              <a class="link">{{ channel.contact_website }}</a>
            </div>
          </div>
        </div> -->

        <signup-block></signup-block>
        <div class="panel">
          <div class="panel-body text-center">
            <add-this></add-this>
          </div>
        </div>
        <div class="hidden-xs">
          <ads-block position="right"></ads-block>
        </div>
      </div>
    </div>

    <div v-if="enable_ad_bar()" class="visible-xs">
      <ads-block position="mbottom"></ads-block>
    </div>
  </div>
</template>

<script>
import GoogleCaptcha from "../components/custom/google-captcha.vue";
import AdBar from "../components/custom/ad-bar";

export default {
  data() {
    return {
      pending: false,
      sent: false,
      error: null,
      captcha: {
        value: {}
      },
      data: {},
      channel: this.$store.getters.info
    };
  },
  methods: {
    submit() {
      this.pending = true;
      this.data.captcha = this.captcha.value.value;

      var formData = new FormData();
      formData.append("liveid", this.$store.getters.liveid);
      for (var f in this.data) formData.append(f, this.data[f]);

      this.error = null;
      this.$http
        .post("content/contact-us.php", formData)
        .then(function(response) {
          if (response.body.success) {
            this.sent = true;
          } else if (response.body.error) {
            this.error = response.body.error;
          }

          this.pending = false;
        });
    }
  },
  components: {
    "google-captcha": GoogleCaptcha
  },
  mixins: [AdBar]
};
</script>
